// Fonts styles
// ---------------

// Fontawesome
$fa-font-path: "../fonts/";
@import "../../node_modules/font-awesome/scss/font-awesome";

// Simple line icons
$simple-line-font-path: "../fonts/";
@import "../../node_modules/simple-line-icons/scss/simple-line-icons";

// Flag icons css
$flag-icon-css-path: "../images/flags";
@import "../../node_modules/flag-icon-css/sass/flag-icon";

// MK Iconfont
$mk-font-path: "../fonts/";
@import "../../node_modules/@mehrkanal/mk-fontbuilder/private/scss/mk-font";

// Project Font
// ------------
$font-path: "../fonts/";
@font-face {
  font-family:'NouvelR';
  src:	url($font-path + 'NouvelR-Light.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family:'NouvelR';
  src:	url($font-path + 'NouvelR-Book.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family:'NouvelR';
  src:	url($font-path + 'NouvelR-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family:'NouvelR';
  src:	url($font-path + 'NouvelR-Semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family:'NouvelR';
  src:	url($font-path + 'NouvelR-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family:'NouvelR';
  src:	url($font-path + 'NouvelR-Extrabold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family:'NouvelRVariable';
  src:	url($font-path + 'NouvelR-Variable.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}
